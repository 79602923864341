import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  Typography,
  Link,
  Container,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";

import cards from "../data/cards.js";
import "../styles/components/Carousel.css";

const CardCarousel = () => {
  const responsive = {
    xs: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
    sm: {
      breakpoint: { max: 960, min: 601 },
      items: 2,
    },
    lg: {
      breakpoint: { max: 1920, min: 961 },
      items: 3,
    },
  };
  return (
    <Container className="carouselContainer">
      <Carousel
        responsive={responsive}
        infinite
        autoPlay={true}
        autoPlaySpeed={2000}
        showDots={true}
        containerClass="carousel-container"
        customDot={<CustomDot />}
        customLeftArrow={<div className="customArrow"></div>}
        customRightArrow={<div className="customArrow"></div>}
      >
        {cards.map((card, index) => (
          <Container key={index}>
            <Card className="card">
              <Link href={card.link} underline="none">
                <CardMedia
                  component="div"
                  sx={{
                    pt: "75%",
                    backgroundSize: "contain",
                  }}
                  image={card.image}
                  className="cardImage"
                >
                  <Container className="cardOverlay" />
                </CardMedia>
              </Link>
              <CardContent
                sx={{ flexGrow: 1, paddingBottom: "10px !important" }}
              >
                <Link href={card.link} underline="none">
                  <Typography gutterBottom variant="h6" className="cardHeading">
                    {card.heading}
                  </Typography>
                </Link>
              </CardContent>
            </Card>
          </Container>
        ))}
      </Carousel>
    </Container>
  );
};

const CustomDot = ({ onClick, active }) => {
  return (
    <div className={`dot ${active ? "activeDot" : ""}`} onClick={onClick}></div>
  );
};

export default CardCarousel;

import React, { useRef, useEffect, useState } from "react";

import {
  AppBar,
  Box,
  CssBaseline,
  IconButton,
  Toolbar,
  List,
  Grid,
  SwipeableDrawer,
  ListItem,
  ListItemText,
  Divider,
  Button,
  Link,
  Popper,
  Container,
  Collapse,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import logo from "../logo.svg";

//custom styles
import "../styles/components/Header.css";

const Header = ({ updateContentMargin }) => {
  const [state, setState] = useState(false);

  const toggleDrawer = () => {
    setState(!state);
  };

  const handleCloseDrawer = (event) => {
    if (event.target.closest("#drawer") === null) {
      setState(false);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const appBarRef = useRef(null);

  useEffect(() => {
    const updateContentMarginTop = () => {
      const appBarHeight = appBarRef.current.clientHeight;
      document.body.style.paddingTop = `${appBarHeight}px`;
      updateContentMargin(appBarHeight);
    };

    window.addEventListener("resize", updateContentMarginTop);
    updateContentMarginTop();

    return () => {
      window.removeEventListener("resize", updateContentMarginTop);
    };
  }, [updateContentMargin]);

  const [listOpen, setlistOpen] = useState(true);

  const handleClick = () => {
    setlistOpen(!listOpen);
  };

  return (
    <AppBar component="nav" className="appbar" position="fixed" ref={appBarRef}>
      <CssBaseline />
      <Toolbar className="nav">
        <Link href="/" underline="none">
          <img
            src={logo}
            alt="Logo"
            style={{ width: "100px" }}
            loading="lazy"
          />
        </Link>
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Link href="/" underline="none" mr={2}>
            <Button className="nav-links">Home</Button>
          </Link>
          <Link href="/about-us" underline="none" mr={2}>
            <Button className="nav-links">About Us</Button>
          </Link>
          <Container
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
              display: "initial",
              padding: "0 !important",
              marginRight: "16px !important",
            }}
          >
            <Button className="nav-links">Products</Button>
            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              sx={{ zIndex: 9999, marginTop: "10px !important" }}
            >
              <Box
                sx={{
                  p: 1,
                  bgcolor: "background.paper",
                  border: "2px solid orange",
                  boxShadow: "2px 2px 5px #8f8e8e",
                }}
              >
                <List sx={{ padding: "0 !important" }}>
                  <ListItem sx={{ padding: "4px 16px !important" }}>
                    <Link
                      href="/products/medicine"
                      underline="none"
                      sx={{
                        color: "#ffa12a",
                        ":hover": {
                          color: "#ef8600",
                        },
                      }}
                    >
                      <ListItemText
                        primary="Medicine"
                        sx={{
                          fontSize: "1.1rem",
                        }}
                      />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem sx={{ padding: "4px 16px !important" }}>
                    <Link
                      href="/products/supplements"
                      underline="none"
                      sx={{
                        color: "#ffa12a",
                        ":hover": {
                          color: "#ef8600",
                        },
                      }}
                    >
                      <ListItemText primary="Supplements" />
                    </Link>
                  </ListItem>
                  <Divider />
                  <ListItem sx={{ padding: "4px 16px !important" }}>
                    <Link
                      href="/products/cosmetics"
                      underline="none"
                      sx={{
                        color: "#ffa12a",
                        ":hover": {
                          color: "#ef8600",
                        },
                      }}
                    >
                      <ListItemText primary="Cosmetics" />
                    </Link>
                  </ListItem>
                </List>
              </Box>
            </Popper>
          </Container>
          <Link href="/contact" underline="none">
            <Button className="nav-links">Contact</Button>
          </Link>
        </Box>
        <IconButton
          color="inherit"
          edge="start"
          onClick={toggleDrawer}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          anchor="left"
          open={state}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
          onClick={handleCloseDrawer}
          onKeyDown={handleCloseDrawer}
          sx={{ display: { sm: "none" } }}
          id="drawer"
        >
          <Box
            sx={{
              width: 250,
            }}
            role="presentation"
          >
            <List>
              <ListItem sx={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={logo}
                  alt="Logo"
                  style={{ width: "75px" }}
                  loading="lazy"
                />
              </ListItem>
              <Divider />
              <Grid container direction="column" p={2}>
                <ListItem>
                  <Link href="/" underline="none" color="black">
                    <ListItemText primary="Home" />
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="/about-us" underline="none" color="black">
                    <ListItemText primary="About Us" />
                  </Link>
                </ListItem>
                <ListItem onClick={handleClick}>
                  <ListItemText primary="Products" />
                  {listOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={listOpen} timeout="auto" unmountOnExit>
                  <ListItem>
                    <Link
                      href="/products/medicine"
                      underline="none"
                      color="black"
                    >
                      <ListItemText sx={{ pl: 4 }} primary="Medicine" />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      href="/products/supplements"
                      underline="none"
                      color="black"
                    >
                      <ListItemText sx={{ pl: 4 }} primary="Supplements" />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      href="/products/cosmetics"
                      underline="none"
                      color="black"
                    >
                      <ListItemText sx={{ pl: 4 }} primary="Cosmetics" />
                    </Link>
                  </ListItem>
                </Collapse>

                <ListItem>
                  <Link href="/contact" underline="none" color="black">
                    <ListItemText primary="Contact" />
                  </Link>
                </ListItem>
              </Grid>
            </List>
          </Box>
        </SwipeableDrawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { useState } from "react";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import Medicine from "./pages/products/Medicine";
import Supplement from "./pages/products/Supplement";
import Cosmetics from "./pages/products/Cosmetics";

function App() {
  const [contentMargin, setContentMargin] = useState(0);

  const updateContentMargin = (margin) => {
    setContentMargin(margin);
  };
  return (
    <Router basename="/">
      <Header updateContentMargin={updateContentMargin} />
      <Routes>
        <Route path="/" element={<Home contentMargin={contentMargin} />} />
        <Route path="about-us" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="products/medicine" element={<Medicine />} />
        <Route path="products/supplements" element={<Supplement />} />
        <Route path="products/cosmetics" element={<Cosmetics />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

import { useState, useEffect, useCallback } from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { useForm } from "@formspree/react";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import AlarmIcon from "@mui/icons-material/Alarm";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonIcon from "@mui/icons-material/Person";
import MailIcon from "@mui/icons-material/Mail";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

import "../styles/pages/Contact.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [state, handleSubmit] = useForm("mrgwndev", {
    data: {
      name: name,
      phone: phone,
      email: email,
      message: message,
    },
  });

  const handleFormSubmit = useCallback(() => {
    if (state.succeeded) {
      console.log("Submitted");
      window.location.reload();
    }
  }, [state.succeeded]);

  useEffect(() => {
    handleFormSubmit();
  }, [handleFormSubmit]);

  return (
    <Container className="contactContainer">
      <Helmet>
        <title>Contact - Cadila Nepal</title>
      </Helmet>
      <Container className="titleContainer">
        <Container className="contactOverlay"></Container>
        <Typography variant="h4" className="contactTitle">
          Contact
        </Typography>
      </Container>
      <Container
        className="contact"
        sx={{
          padding: {
            xs: "40px 30px 30px 40px !important",
            md: "50px 30px 30px 60px !important",
          },
        }}
      >
        <Grid container spacing={2} mt={4} sx={{ width: "100%", margin: 0 }}>
          <Grid item xs={12} md={7}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", color: "#f48900", marginBottom: 4 }}
            >
              Get in Touch With Us
            </Typography>

            <Grid
              container
              rowSpacing={4}
              columnSpacing={3}
              sx={{ wordWrap: "break-word" }}
            >
              <Grid item xs={12} sm={6}>
                <LocationOnIcon
                  className="contactIcons"
                  sx={{ fontSize: 35 }}
                />
                <Typography variant="h5" className="iconsTitle">
                  Address
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "1.1rem" }} mt={1}>
                  Kalimati-13, Kathmandu, Nepal
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <AlarmIcon className="contactIcons" sx={{ fontSize: 35 }} />
                <Typography variant="h5" className="iconsTitle">
                  Opening Hours
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "1.1rem" }} mt={1}>
                  10am to 5pm
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <MailOutlineIcon
                  className="contactIcons"
                  sx={{ fontSize: 35 }}
                />
                <Typography variant="h5" className="iconsTitle">
                  Mail
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "1.1rem" }} mt={1}>
                  cadila.nepalrb2023@gmail.com
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <PhoneIcon className="contactIcons" sx={{ fontSize: 35 }} />
                <Typography variant="h5" className="iconsTitle">
                  Contact
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "1.1rem" }} mt={1}>
                  +977-9823352003
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={9}
            md={5}
            sx={{
              padding: "0 !important",
              mt: { xs: "30px", md: 0 },
              mb: "20px",
            }}
          >
            <Box
              component="form"
              className="formContainer"
              onSubmit={handleSubmit}
            >
              <Typography variant="h5">Contact us Directly</Typography>
              <TextField
                placeholder="Enter your name"
                fullWidth
                sx={{ marginTop: 4 }}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon fontSize="small" sx={{ color: "white" }} />
                    </InputAdornment>
                  ),
                  style: { color: "white", borderBottom: "1px solid #c9c9c9" },
                  className: "inputField",
                }}
                variant="standard"
              />
              <TextField
                placeholder="Phone Number"
                fullWidth
                sx={{ marginTop: 4 }}
                value={phone}
                required
                type="number"
                onChange={(e) => setPhone(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon fontSize="small" sx={{ color: "white" }} />
                    </InputAdornment>
                  ),
                  style: { color: "white", borderBottom: "1px solid #c9c9c9" },
                  className: "inputField",
                }}
                variant="standard"
              />
              <TextField
                placeholder="Email Address"
                fullWidth
                sx={{ marginTop: 4 }}
                value={email}
                type="email"
                required
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon fontSize="small" sx={{ color: "white" }} />
                    </InputAdornment>
                  ),
                  style: { color: "white", borderBottom: "1px solid #c9c9c9" },
                  className: "inputField",
                }}
                variant="standard"
              />
              <TextField
                placeholder="Enter your message"
                sx={{ marginTop: 4 }}
                fullWidth
                multiline
                minRows={3}
                value={message}
                required
                onChange={(e) => setMessage(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <QuestionAnswerIcon
                        fontSize="small"
                        sx={{ color: "white" }}
                      />
                    </InputAdornment>
                  ),
                  style: {
                    color: "white",
                    borderBottom: "1px solid #c9c9c9",
                    alignItems: "baseline",
                  },
                  className: "inputField",
                }}
                variant="standard"
              />

              <Button
                type="submit"
                variant="contained"
                disableElevation
                className="formSubmit"
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default Contact;

import {
  Container,
  Grid,
  Typography,
  CardMedia,
  Card,
  CardContent,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";

import "../../styles/products/Cosmetics.css";
import cosmetic from "../../data/cosmetics.json";
import ContentNotAvailable from "../../images/content.svg";

const Cosmetics = () => {
  const [viewportRatio, setViewportRatio] = useState(0);

  useEffect(() => {
    const updateViewportRatio = () => {
      const vw = window.innerWidth;
      const vh = window.innerHeight;
      const ratio = vh / vw;

      setViewportRatio(ratio);
    };
    window.addEventListener("resize", updateViewportRatio);
    updateViewportRatio();
    return () => {
      window.removeEventListener("resize", updateViewportRatio);
    };
  }, []);

  return (
    <Container
      className={`cosmeticContainer ${
        viewportRatio > 1.5 ? "heightClass" : ""
      }`}
    >
      <Helmet>
        <title>Products - Cosmetics - Cadila Nepal</title>
      </Helmet>
      <Container className="cosmeticTitleContainer">
        <Container className="cosmeticOverlay"></Container>
        <Typography variant="h4" className="cosmeticTitle">
          Products - Cosmetics
        </Typography>
      </Container>

      <Container
        sx={{
          width: { xs: "100%", lg: "65%" },
          padding: {
            xs: "40px 20px !important",
            md: "60px 20px!important",
          },
          flex: 1,
          display: "flex",
        }}
      >
        <img
          src={ContentNotAvailable}
          alt="Error 404"
          width="100%"
          height="auto"
          loading="lazy"
        />
      </Container>

      {/* <Container
        sx={{
          padding: {
            xs: "40px !important",
            md: "60px !important",
          },
        }}
      >
        <Grid container rowSpacing={6} columnSpacing={4}>
          {cosmetic.map((card) => (
            <Grid item key={card} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardMedia
                  component="div"
                  sx={{
                    // 16:9
                    pt: "56.25%",
                  }}
                  image={card.image}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    sx={{ fontWeight: "bold" }}
                  >
                    {card.heading}
                  </Typography>
                  <Typography
                    variat="h5"
                    sx={{ color: "#f57835", fontWeight: 600 }}
                  >
                    {card.price}
                  </Typography>
                  <Typography sx={{ color: "#00758d" }}>
                    {card.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container> */}
    </Container>
  );
};

export default Cosmetics;

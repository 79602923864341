import { Container } from "@mui/material";
import "../styles/pages/Error.css";
import Error404 from "../images/Error404.svg";

const Error = () => {
  return (
    <Container className="errorContainer">
      <Container sx={{ width: { xs: "90%", md: "50%" } }}>
        <img
          src={Error404}
          alt="Error 404"
          width="100%"
          height="auto"
          loading="lazy"
        />
      </Container>
    </Container>
  );
};

export default Error;

import { Suspense } from "react";
import { Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

import "../styles/pages/About.css";
import Loading from "../components/Loading";

const About = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Container className="aboutContainer">
        <Helmet>
          <title>About Us - Cadila Nepal</title>
        </Helmet>
        <Container className="titleContainer">
          <Container className="aboutOverlay"></Container>
          <Typography variant="h4" className="aboutTitle">
            About Us
          </Typography>
        </Container>
        <Typography variant="body1" className="about">
          <span
            style={{ fontSize: "1.5rem", color: "#f57835", fontWeight: 600 }}
          >
            Cadila Nepal&nbsp;
          </span>
          is one of the leading Importers and Distributors of Nepal dealing in
          various types of Pharmaceutical Formulations, Surgical items,
          cosmetics, nutraceutical products, dietary supplements and so on. We
          have presence in almost all the distribution segments such as Retail,
          Wholesale, Govt. supplies, Corporate Hospitals, Institution supplies,
          Super Stockiest. Cadila Nepal promotes only the highest quality
          products that help improve the lives of our customers and surrounding
          communities.
          <br /> <br />
          Introducing to our team, our team has an experience of over 20 years
          in the field of healthcare sector. Our Team has a chain of polyclinic
          and hospitals established and are the pioneer and the best center in
          Nepal for Physiotherapy, Rehabilitation, Ayurvedic, Natural and
          holistic medicine. We are privileged to connect with thousands of
          customers every day with quality healthcare/pharmaceutical products.
        </Typography>
      </Container>
    </Suspense>
  );
};

export default About;

import ProductLaunch from "../images/news/product-launch.jpg";
import Cadiflex from "../images/news/cadiflex.png";
import CadicalCl from "../images/news/cadical-cl.png";
import CadicalK27 from "../images/news/cadical-k27.jpg";
import FlexOJoint from "../images/news/flex-o-joint.jpg";

const cards = [
  {
    image: ProductLaunch,
    heading: "Products Launch (1 January 2024)",
    link: "/",
  },
  {
    image: Cadiflex,
    heading: "Product Launch of Cadiflex",
    link: "/products/supplements",
  },
  {
    image: CadicalCl,
    heading: "Product Launch of Cadical CL",
    link: "/products/supplements",
  },
  {
    image: CadicalK27,
    heading: "Product Launch of Cadical K\u20827",
    link: "/products/supplements",
  },
  {
    image: FlexOJoint,
    heading: "Product Launch of Flex-O-Joint",
    link: "/products/supplements",
  },
];

export default cards;

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CardMedia } from "@mui/material";
import "../styles/components/ProductCarousel.css";

const ProductCarousel = ({ product }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      infinite
      autoPlay={true}
      autoPlaySpeed={2000}
      showDots={true}
      customDot={<CustomDot />}
      customLeftArrow={<div style={{ display: "none" }}></div>}
      customRightArrow={<div style={{ display: "none" }}></div>}
      style={{ marginBottom: 0 }}
    >
      {product.images.map((image, index) => (
        <CardMedia
          key={index}
          component="div"
          className="product"
          sx={{
            pt: "75%",
          }}
          image={image}
        />
      ))}
    </Carousel>
  );
};

const CustomDot = ({ onClick, active }) => {
  return (
    <div
      className={`productDot ${active ? "activeProduct" : ""}`}
      onClick={onClick}
    ></div>
  );
};

export default ProductCarousel;

import { Suspense } from "react";
import {
  Grid,
  Typography,
  Button,
  Link,
  Container,
  Card,
  CardMedia,
} from "@mui/material";

import "../styles/pages/Home.css";
import office from "../images/office.jpg";
import CardCarousel from "../components/Carousel";
import Loading from "../components/Loading";
import associates from "../data/associates";

const Home = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Container className="homeContainer">
        <Grid container spacing={3} mb={8}>
          <Grid item xs={12} md={6}>
            <img
              src={office}
              alt="Office"
              width="65%"
              className="officeImage"
              loading="lazy"
            />
          </Grid>
          <Grid item xs={12} md={6} mt={4}>
            <Typography
              variant="h4"
              component="h1"
              color="#f48900"
              sx={{ fontWeight: 600, fontSize: "2.5rem" }}
            >
              About Cadila Nepal
            </Typography>
            <Typography
              variant="body1"
              mt={3}
              sx={{ width: "95%", lineHeight: "2.2rem" }}
            >
              <span
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  color: "#007890",
                }}
              >
                Cadila Nepal{" "}
              </span>
              is one of the leading Importers and Distributors of Nepal dealing
              in various types of Pharmaceutical Formulations, Surgical items,
              cosmetics, nutraceutical products, dietary supplements and so on.
              Cadila Nepal promotes only the highest quality products that help
              improve the lives of our customers and surrounding communities.
              <br />
              Our Group has a chain of polyclinic and hospitals established and
              are the pioneer and the best center in Nepal for Physiotherapy,
              Rehabilitation, Ayurvedic, natural and holistic medicine.
            </Typography>
            <Link href="/about-us">
              <Button variant="contained" size="large" className="readMore">
                Read More &gt;&gt;
              </Button>
            </Link>
          </Grid>
        </Grid>

        <Container className="motto">
          <Container className="overlay"></Container>
          <Typography variant="h4" className="text">
            "Cadila Nepal promotes only the highest quality products that help
            improve the lives of our customers and surrounding communities."
          </Typography>
        </Container>

        <Container className="newsContainer">
          <Typography variant="h4" component="h2" className="newsTitle">
            News and Activities
          </Typography>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} sx={{ padding: "0 !important" }}>
              <CardCarousel />
            </Grid>
          </Grid>
        </Container>

        <Container sx={{ mt: "100px" }}>
          <Typography
            variant="h4"
            component="h2"
            sx={{
              fontWeight: "bold !important",
              textAlign: "center",
              color: "#158ca1",
            }}
          >
            Our Associates
          </Typography>
          <Grid
            container
            columnSpacing={5}
            rowSpacing={2}
            mt={2}
            sx={{ justifyContent: "center" }}
          >
            {associates.map((associate, index) => (
              <Grid item xs={8} sm={6} md={4}>
                <Card sx={{ padding: "20px", border: "1px solid #bdbcbc" }}>
                  <CardMedia
                    component="div"
                    sx={{
                      pt: "50%",
                      backgroundSize: "contain",
                    }}
                    image={associate.image}
                  ></CardMedia>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Container>
    </Suspense>
  );
};

export default Home;

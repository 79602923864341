import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Grid,
  Typography,
  Container,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";

import { SocialIcon } from "react-social-icons";

import logo from "../logo.svg";
import "../styles/components/Footer.css";

const defaultTheme = createTheme();

const Footer = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box component="footer" className="footer">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <img
              src={logo}
              alt="Logo"
              style={{ width: "120px" }}
              loading="lazy"
            />
            <Typography
              variant="body1"
              gutterBottom
              sx={{ fontSize: "0.93rem" }}
            >
              Cadila Nepal is one of the leading Importers and Distributors of
              Nepal dealing in various types of Pharmaceutical Formulations,
              Surgical items, cosmetics, nutraceutical products, dietary
              supplements and so on.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h5" mb={2}>
              Contact Us
            </Typography>
            <Grid container>
              <List m={0} sx={{ p: 0 }}>
                <ListItem sx={{ py: 0.5, px: 0 }}>
                  <ListItemIcon className="icons">
                    <LocationOnIcon />
                  </ListItemIcon>
                  <ListItemText primary="Kalimati-13, Kathmandu, Nepal" />
                </ListItem>
                <ListItem sx={{ py: 0.5, px: 0 }}>
                  <ListItemIcon className="icons">
                    <PhoneIcon />
                  </ListItemIcon>
                  <ListItemText primary="+977-9823352003" />
                </ListItem>
                <ListItem sx={{ py: 0.5, px: 0 }}>
                  <ListItemIcon className="icons">
                    <MailIcon />
                  </ListItemIcon>
                  <Link
                    href="mailto:cadila.nepalrb2023@gmail.com"
                    target="_blank"
                    underline="none"
                    mr={2}
                    className="mail"
                  >
                    <ListItemText primary="cadila.nepalrb2023@gmail.com" />
                  </Link>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4} sx={{ textAlign: "center" }}>
            <SocialIcon
              network="facebook"
              target="_blank"
              url="https://www.facebook.com/profile.php?id=61554852127150&mibextid=LQQJ4d"
              className="socialIcons"
            />

            <SocialIcon
              network="instagram"
              target="_blank"
              url="https://www.instagram.com"
              className="socialIcons"
            />
            <SocialIcon
              network="linkedin"
              target="_blank"
              url="https://www.linkedin.com/company/cadila-nepal-pvt-ltd/"
              className="socialIcons"
            />
          </Grid>
        </Grid>
      </Box>
      <Container className="copyright">
        <Typography variant="body2" color="white">
          Copyright &copy; Cadila Nepal Private Limited&nbsp;
          {new Date().getFullYear()}. All Rights Reserved.
        </Typography>
      </Container>
    </ThemeProvider>
  );
};

export default Footer;

import Cadiflex1 from "../images/products/supplement/cadiflex.jpg";
import CadicalCl1 from "../images/products/supplement/cadical-cl.jpg";
import CadicalK271 from "../images/products/supplement/cadical-k27.jpg";
import FlexOJoint1 from "../images/products/supplement/flex-o-joint.jpg";

import Cadiflex2 from "../images/products/supplement/cadiflex2.png";
import CadicalCl2 from "../images/products/supplement/cadical-cl2.png";
import CadicalK272 from "../images/products/supplement/cadical-k272.png";
import FlexOJoint2 from "../images/products/supplement/flex-o-joint2.png";

const supplement = [
  {
    images: [Cadiflex1, Cadiflex2],
    heading: "Cadiflex",
    price: "Rs xxx",
    description: "Multivitamin",
  },
  {
    images: [CadicalCl1, CadicalCl2],
    heading: "Cadical CL",
    price: "Rs xxx",
    description: "Milk Calcium",
  },
  {
    images: [CadicalK271, CadicalK272],
    heading: "Cadical K\u20827",
    price: "Rs xxx",
    description: "Calcium",
  },
  {
    images: [FlexOJoint1, FlexOJoint2],
    heading: "Flex-O-Joint",
    price: "Rs xxx",
    description: "Neutraceuticals",
  },
];

export default supplement;

import SparkPolyclinic from "../images/associates/spark-polyclinic.jpg";
import SparkHealth from "../images/associates/spark-health.jpg";

const associates = [
  {
    image: SparkPolyclinic,
  },
  {
    image: SparkHealth,
  },
];

export default associates;

import {
  Container,
  Grid,
  Typography,
  Card,
  Tooltip,
  CardContent,
  Zoom,
  Link,
} from "@mui/material";
import { Helmet } from "react-helmet";

import "../../styles/products/Supplement.css";
import supplement from "../../data/supplements.js";
import ProductCarousel from "../../components/ProductCarousel.js";

const Supplement = () => {
  return (
    <Container className="supplementContainer">
      <Helmet>
        <title>Products - Supplements - Cadila Nepal</title>
      </Helmet>
      <Container className="supplementTitleContainer">
        <Container className="supplementOverlay"></Container>
        <Typography variant="h4" className="supplementTitle">
          Products - Supplements
        </Typography>
      </Container>

      <Container
        sx={{
          padding: {
            xs: "40px !important",
            md: "60px !important",
          },
        }}
      >
        <Grid container rowSpacing={6} columnSpacing={4}>
          {supplement.map((card) => (
            <Grid item key={card.heading} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  // height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <ProductCarousel product={card} />
                <Link href="/contact" underline="none" color={"black"}>
                  <Tooltip
                    title="Contact us for price and other inquiries"
                    // placement="right"
                    followCursor
                    arrow
                    TransitionComponent={Zoom}
                    enterTouchDelay={0}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        color={"black"}
                        sx={{ fontWeight: "bold" }}
                      >
                        {card.heading}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ color: "#f57835", fontWeight: 600 }}
                      >
                        {card.price}
                      </Typography>
                      <Typography sx={{ color: "#00758d" }}>
                        {card.description}
                      </Typography>
                    </CardContent>
                  </Tooltip>
                </Link>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Container>
  );
};

export default Supplement;
